// ** Third Party Components
import styled from "styled-components";

// #####################################################

export const StyledImageContainer = styled("div")`
	display: inline-block;
	width: 100%;
	position: relative;

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	:before {
		content: "";
		display: block;
		padding-top: 100%;
	}
`;

// #####################################################
