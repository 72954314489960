import { createElement } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import Heading from "components/typography/Heading";
import {
	resolveProps,
	marginable,
	fontable,
} from "../../../styles/styledComponentsMixins";

const Paragraph = styled(({ textCenter, fullContent, ...props }) =>
	createElement("p", resolveProps(props, marginable, fontable))
)`
	display: block;

	& + &,
	${Heading} + & {
		margin-top: 0.5rem;
	}

	${marginable.css}
	${fontable.css}
	${({ textCenter }) =>
		textCenter &&
		css`
			text-align: center;
		`}

		${({ fullContent }) =>
		fullContent &&
		css`
			width: 100%;
		`}
`;

Paragraph.defaultProps = {
	...marginable.defaultPropsDefinition,
	...fontable.defaultPropsDefinition,
	textCenter: false,
	mb: 4,
};

Paragraph.propTypes = {
	...marginable.propTypesDefinition,
	...fontable.propTypesDefinition,
	center: PropTypes.bool,
};

Paragraph.displayName = "Paragraph";

export default Paragraph;
