import { createElement } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { resolveProps, fontable } from "../../styles/styledComponentsMixins";

const LinkNative = styled(
	({ iblock, block, underline, noColorVisited, ...props }) =>
		createElement("a", resolveProps(props, fontable))
)`
	${fontable.css}
	${({ iblock }) =>
		iblock &&
		css`
			display: inline-block;
		`}
	${({ block }) =>
		block &&
		css`
			display: block;
		`}
	${({ underline }) =>
		underline &&
		css`
			&:hover {
				text-decoration: underline;
			}
		`}

		${({ noColorVisited }) =>
		noColorVisited &&
		css`
			&:visited {
				color: inherit;
			}
		`}
`;

LinkNative.defaultProps = {
	...fontable.defaultPropsDefinition,
	iblock: false,
	block: false,
};

LinkNative.propTypes = {
	...fontable.propTypesDefinition,
	href: PropTypes.string.isRequired,
	iblock: PropTypes.bool,
	block: PropTypes.bool,
};

export default LinkNative;
