// ** Third Party Components
import styled from "styled-components";

// #####################################################

export const StyledPicturesGrid = styled("ul")`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr 1fr;
	list-style: none;
	padding: 0;
	flex: 1;
	li {
		padding: 0.35rem;
	}
`;

// #####################################################
