import { createElement } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { headingTypes } from "../../styles/declares";
import {
	resolveProps,
	marginable,
	fontable,
} from "../../styles/styledComponentsMixins";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Heading = styled(({ type, inline, alwaysLight, ...props }) =>
	createElement(type, resolveProps(props, marginable, fontable))
)(({ theme, type, inline }) => {
	const headingType = theme.headings[type];
	return css`
		${inline &&
		css`
			display: inline-block;
		`}
		font-size: ${headingType.fontSize}rem;
		${marginable.css}
		${fontable.css}
		font-weight: bold;

		${({ alwaysLight }) =>
			alwaysLight &&
			css`
				color: ${({ theme: { colorsDefaults } }) =>
					colorsDefaults.black};
			`}
	`;
});

Heading.propTypes = {
	...marginable.defaultPropsDefinition,
	...fontable.defaultPropsDefinition,
};

Heading.propTypes = {
	...marginable.propTypesDefinition,
	...fontable.propTypesDefinition,
	type: PropTypes.oneOf([...Object.keys(headingTypes)]),
};

Heading.displayName = "Heading";

export default Heading;
