// ** Third Party Components
import styled from "styled-components";

// ** Styles Import
import { breakpoints } from "styles/grid";

// #####################################################

export const StyledGridListPreference = styled("ul")`
	margin: 0;
	display: grid;
	position: relative;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(4, minmax(0, 1fr));
	@media (max-width: ${breakpoints.phone - 1}px) {
		grid-template-columns: repeat(1, 1fr);
		grid-template-rows: repeat(12, minmax(0, 1fr));
	}
	grid-auto-flow: column;
`;

// #####################################################
